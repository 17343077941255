import * as React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import Img from 'gatsby-image';
import {
  PostCardWrapper,
  PostPreview,
  PostDetails,
  PostDate,
  PostTitle,
  Excerpt,
  PostContent,
  PostTags,
} from './post-card.style';

interface PostCardProps {
  image?: any;
  title: string;
  description?: string;
  url: string;
  sdgs_no: string;
  no: string;
  height_name: string;
  color_name: string;
  date?: string;
  tags?: [];
  className?: string;
  imageType?: 'fixed' | 'fluid';
}

const PostCard: React.FunctionComponent<PostCardProps> = ({
  image,
  title,
  description,
  url,
  date,
  tags,
  className,
  imageType,
  sdgs_no,
  height_name,
  color_name,
  no,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['post_card'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <div>
    {/* <div className={addAllClasses.join(' ')} {...props}> */}
      {/* {image == null ? null : (
        <PostPreview className="post_preview">
          <Link to={url}>
              <Img to={"/sdgsicon/sdg_"+sdgs_no+".png"} alt="post preview" />
          </Link>
        </PostPreview>
      )} */}

      <div className="post_details">
        {/* {date && (
          <PostDate
            dangerouslySetInnerHTML={{
              __html: date,
            }}
            className="post_date"
          />
        )} */}
        <div>

        <div style={{display:"flex"}}>
          <div>
            <a href={"/tags/sdgs-"+sdgs_no}><img style={{width:"5vw",margin:"2px"}} src={"/sdgsicon/sdg_"+sdgs_no+".png"}/></a>
          </div>
          <div>
          <PostTitle style={{margin:"0 0 0 0"}}>
            <Link style={{margin:"0 0 0 0"}} to={url+"a/#t"}>　{no}　{title}
            <span style={{fontSize:"small"}}>
            　（
          {height_name} × {color_name}
            ）
            </span>

          {/* {try} */}
            </Link>

{/* 
          {description && (
            <Excerpt
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            className="excerpt"
            />
            )}
 */}
{/* 
          {tags == null ? null : (
            <PostTags className="post_tags">
              {tags.map((tag: string, index: number) => (
                <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                  {`#${tag}`}
                </Link>
              ))}
            </PostTags>
          )}
 */}

          </PostTitle>

        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

PostCard.defaultProps = {
  imageType: 'fluid',
};

export default PostCard;
